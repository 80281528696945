<!--
 * @Author: wjc
 * @Date: 2023-11-01 15:53:21
 * @LastEditors: LiZhiWei
 * @LastEditTime: 2024-11-26 09:10:24
 * @Description: 
-->
<template>
  <Headers @login="onLogin" @register="onRegister"></Headers>
  <Menu :open="isScreenOpen" @toggle-screen="toggleScreen"></Menu>
  <MenuScreen :open="isScreenOpen"></MenuScreen>
  <main>
    <slot></slot>
  </main>
  <el-backtop :visibility-height="400" :right="20" :bottom="100">
    <span class="i-custom-fhdb"></span>
  </el-backtop>
  <Footer></Footer>
  <ClientOnly>
    <Login
      :open="openLogin"
      :is-open-register="openRegister"
      @closeLogin="onCloseLogin"
      @closeRegister="() => (openRegister = false)"
    ></Login>
  </ClientOnly>
</template>

<script lang="ts" setup>
  import Headers from './components/headers.vue'
  import Menu from './components/menu.vue'
  import MenuScreen from './components/menu-screen/index.vue'
  import Footer from './components/footer.vue'
  import Login from '~/components/Login/login.vue'
  import { useUserStore } from '~/stores/modules/user/user'
  import { useLoginStore } from '~/stores/modules/user/login'
  defineOptions({
    name: 'LayoutDefault',
  })

  const { isScreenOpen, closeScreen, toggleScreen } = useNav()

  provide('close-screen', closeScreen)
  const openLogin = ref(false)
  const openRegister = ref(false)

  const onLogin = () => {
    openLogin.value = true
  }
  const onRegister = () => {
    openLogin.value = true
    openRegister.value = true
  }
  const onCloseLogin = () => {
    openLogin.value = false
    openRegister.value = false
  }
</script>
